<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <div>
        <!-- {{ products }} -->
        <!-- {{ selectedLocation }} -->
        <b-card title="Informationen">
          <b-row class="">
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Gewerblich?" label-for="v-isOrganization">
                <b-form-checkbox
                  v-model="customer.isOrganization"
                  switch
                  inline
                >
                  <!-- {{ isCompany ? true : false }} -->
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Unternehmen" label-for="v-organizationName">
                <validation-provider
                  #default="{ errors }"
                  name="Unternehmen"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.organizationName"
                    placeholder="Unternehmensnamen eingeben"
                    :disabled="!customer.isOrganization"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Anrede" label-for="v-salutation">
                <v-select
                  id="v-salutation"
                  v-model="customer.salutation"
                  :clearable="false"
                  :options="getSalutionOptions()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Vorname" label-for="v-firstname">
                <validation-provider
                  #default="{ errors }"
                  name="Vorname"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.firstName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Nachname" label-for="v-lastname">
                <validation-provider
                  #default="{ errors }"
                  name="Nachname"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.lastName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Email" label-for="v-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="customer.email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6"
              ><b-form-group label="Telefon" label-for="phoneNumber">
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                  name="Telefon"
                >
                  <b-form-input
                    v-model="customer.phoneNumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label="Handy" label-for="mobile">
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                  name="Handy"
                >
                  <b-form-input
                    v-model="customer.mobileNumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <!-- Adresse -->
        <!--  -->
        <b-card title="Adresse">
          <b-row class="">
            <b-col sm="9" md="9" lg="10" xl="6">
              <b-form-group label="Strasse" label-for="">
                <validation-provider
                  #default="{ errors }"
                  name="Strasse"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.street"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="3" md="3" lg="2" xl="2">
              <b-form-group label="Hausnummer" label-for="">
                <validation-provider
                  #default="{ errors }"
                  name="Hausnummer"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.streetNumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="">
            <b-col sm="3" md="3" lg="2" xl="2">
              <b-form-group label="PLZ" label-for="">
                <validation-provider
                  #default="{ errors }"
                  name="PLZ"
                  rules="required|digits:5"
                >
                  <b-form-input
                    v-model="customer.zipCode"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="9" md="9" lg="10" xl="6">
              <b-form-group label="Stadt" label-for="">
                <validation-provider
                  #default="{ errors }"
                  name="Stadt"
                  rules="required"
                >
                  <b-form-input
                    v-model="customer.city"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="9" md="9" lg="6" xl="6">
              <b-form-group label="Land" label-for="">
                <validation-provider
                  #default="{ errors }"
                  name="Land"
                  rules="required"
                >
                  <v-select
                    id="v-country"
                    v-model="customer.country"
                    :clearable="false"
                    :options="getCountryOptions()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <label for="textarea-default">Nachricht</label>
          <b-form-textarea
            id="textarea-default"
            v-model="customer.message"
            rows="3"
          />
        </b-card>
      </div>
      <!-- Right Col -->
      <div class="customer-card">
        <b-card>
          Füllen Sie bitte das Formular aus um eine Anfrage zu stellen.
          <hr />
          <h6 class="price-title">Details</h6>
          <ul class="list-unstyled">
            <li
              v-for="(product, index) in products"
              :key="index"
              class="price-detail"
            >
              <div class="detail-title">
                <p>{{ product.qty }} x {{ product.name }}</p>
              </div>
              <div class="detail-title">
                <p>{{ product.location }}</p>
              </div>
              <div class="detail-amt font-weight-bolder">
                <p>{{ product.price }}€</p>
              </div>
              <p class="text-muted">
                Monatliche Containermiete inkl. MwSt.19%. Container werden bis
                auf wiederufen monatlich berechnet.
              </p>
              <div>
                <b-form-checkbox v-model="agbChecked">
                  <p>
                    Ich stimme den
                    <a
                      target="_blank"
                      href="https://dateien.der-lagermeister.de/public/AGB.pdf"
                      >AGB</a
                    >
                    und
                    <a
                      target="_blank"
                      href="https://dateien.der-lagermeister.de/public/DSGVO.pdf"
                      >Datenschutzbestimmungen zu.</a
                    >
                  </p>
                </b-form-checkbox>
              </div>
            </li>
          </ul>

          <b-card-header class="flex-column align-items-start" />
          <b-card-text class="mb-0">
            {{ customer.organizationName }}
          </b-card-text>
          <b-card-text class="mb-0">
            {{ customer.salutation }} {{ customer.firstName }}
            {{ customer.lastName }}
            <b-card-text class="mb-0">
              {{ customer.street }} {{ customer.streetNumber }}
            </b-card-text>
            <b-card-text class="mb-0">
              {{ customer.zipCode }} {{ customer.city }}
            </b-card-text>
            <b-card-text>{{ customer.country }}</b-card-text>
            <b-card-text v-if="customer.email" class="mb-0">
              <span class="text-muted">Email:</span> {{ customer.email }}
            </b-card-text>
            <b-card-text v-if="customer.phoneNumber" class="mb-0">
              <span class="text-muted"> Telefon:</span>
              {{ customer.phoneNumber }}
            </b-card-text>
            <b-card-text v-if="customer.mobileNumber" class="mb-0">
              <span class="text-muted">Mobil:</span> {{ customer.mobileNumber }}
            </b-card-text>

            <b-card-text>{{ customer.message }}</b-card-text>

            <b-button
              :disabled="!agbChecked"
              variant="primary"
              block
              @click="sendInquiryRequest"
            >
              Buchungsanfrage senden!
            </b-button>
          </b-card-text></b-card
        >
      </div>
    </b-form>
    <b-modal
      id="modal-inquiry-checkout"
      ok-only
      ok-title="OK"
      modal-class="modal-primary"
      centered
      title="Danke!"
      @hidden="resetModal"
    >
      <b-card-text>
        Viele Dank für Ihre Anfrage. Wir prüfen die Verfügbarkeit der Container
        und melden uns schnellstmöglich bei Ihnen. <br />
        <br />
        Mit freundlichen Grüßen <br />
        Ihr Team von der-lagermeister.de
      </b-card-text>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
// import { required, integer } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import helper from '@/helper/helper'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  components: {
    // BSV
    BFormCheckbox,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getFormattedDate, getCountryOptions, getSalutionOptions } = helper()

    const products = ref([])

    const fetchCartProducts = () => {
      products.value = store.getters.cart
    }

    fetchCartProducts()

    return {
      getFormattedDate,
      getCountryOptions,
      getSalutionOptions,
      products,
    }
  },

  data() {
    return {
      agbChecked: false,
      customer: {
        isOrganization: false,
        organizationName: '',
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        faxNumber: '',
        mobileNumber: '',
        street: '',
        streetNumber: '',
        zipCode: '',
        city: '',
        country: '',
        message: '',
      },
    }
  },

  computed: {
    selectedLocation() {
      return this.$store.getters.selectedLocation
    },
  },

  methods: {
    // formValidation,

    showModalSuccessInquiry() {
      this.$bvModal.show('modal-inquiry-checkout')
    },

    resetModal() {
      // this.$bvModal.hide('modal-inquiry-checkout')
      this.$store.dispatch('cleanCart')
      this.$router.push({ name: 'lagermeister-shop' })
    },

    sendInquiryRequest() {
      const inquiry = this.generateInquiryMapping()
      this.$store
        .dispatch('addInquiry', inquiry)
        .then(() => {
          console.log('Inquiry created')
          // alert('Inquiry created')
          this.showModalSuccessInquiry()
        })
        .catch(error => {
          console.log(error)
          alert('Error creating inquiry')
        })
    },

    generateInquiryMapping() {
      const storageJSON = {
        storage: this.products.map(storage => ({
          id: storage.storage_type,
          type: storage.name,
          location: this.selectedLocation.id,
          priceStorage: storage.price,
          numberStorage: storage.qty,
          estimatedEndRent: storage.estimatedEndRent,
          estimatedStartRent: storage.estimatedStartRent,
        })),
      }

      const inquiry = {
        salutation: this.customer.salutation,
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        email: this.customer.email,
        phoneNumber: this.customer.phoneNumber,
        mobileNumber: this.customer.mobileNumber,
        street: this.customer.street,
        streetNumber: this.customer.streetNumber,
        zipCode: this.customer.zipCode,
        city: this.customer.city,
        country: this.customer.country,
        message: this.customer.message,
        isOrganization: this.customer.isOrganization,
        organizationName: this.customer.organizationName,
        created_by: 'shop',
        status: 'open',
        offers: [],
        storageLocation: '1',
        leadSource: 'Shop',
        storageJSON,
      }

      return inquiry
    },

    createTestInquiry() {
      const testInquiry = this.generateTestInquiry()
      this.$store
        .dispatch('addInquiry', testInquiry)
        .then(() => {
          console.log('Inquiry created')
          alert('Inquiry created')
        })
        .catch(error => {
          console.log(error)
          alert('Error creating inquiry')
        })
    },

    generateTestInquiry() {
      const salutations = {
        male: ['Herr'],
        female: ['Frau'],
      }
      const firstNames = {
        male: ['Benjamin', 'David', 'Fabian', 'Jan', 'Lukas', 'Max'],
        female: ['Anna', 'Caroline', 'Emilia', 'Hannah', 'Lea', 'Sophie'],
      }
      const lastNames = [
        'Müller',
        'Schmidt',
        'Schneider',
        'Fischer',
        'Weber',
        'Mayer',
      ]
      const messages = [
        'Ich bin an Ihren Lagermöglichkeiten interessiert.',
        'Können Sie mir bitte ein Angebot schicken?',
      ]
      const streets = [
        'Hauptstraße',
        'Bahnhofstraße',
        'Kirchstraße',
        'Mühlenweg',
      ]
      // const cities = [
      //   'Berlin',
      //   'Hamburg',
      //   'München',
      //   'Köln',
      //   'Frankfurt',
      //   'Stuttgart',
      // ]
      // const zipCodes = ['10115', '20095', '80331', '50667', '60311', '70173']
      const phoneNumbers = ['0176-12345678', '0157-23456789', '0162-34567890']
      const leadSources = ['Website', 'Empfehlung', 'Werbung', 'Messe']
      // const storageLocations = [
      //   'Lagerhalle 1',
      //   'Lagerhalle 2',
      //   'Lagerhalle 3',
      //   'Lagercontainer',
      // ]
      const organizationNames = ['Firma A', 'Firma B', 'Firma C']
      const country = 'Deutschland'

      const gender = Math.random() < 0.5 ? 'male' : 'female'
      // eslint-disable-next-line operator-linebreak
      const salutation =
        salutations[gender][
          Math.floor(Math.random() * salutations[gender].length)
        ]
      // eslint-disable-next-line operator-linebreak
      const firstName =
        firstNames[gender][
          Math.floor(Math.random() * firstNames[gender].length)
        ]
      const lastName = lastNames[Math.floor(Math.random() * lastNames.length)]
      const message = messages[Math.floor(Math.random() * messages.length)]
      const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`
      const streetNumber = Math.floor(Math.random() * 100) + 1
      const streetName = streets[Math.floor(Math.random() * streets.length)]
      const city = 'Münster'
      const zipCode = '48157'
      // eslint-disable-next-line operator-linebreak
      const phoneNumber =
        phoneNumbers[Math.floor(Math.random() * phoneNumbers.length)]
      // eslint-disable-next-line operator-linebreak
      const leadSource =
        leadSources[Math.floor(Math.random() * leadSources.length)]
      // eslint-disable-next-line operator-linebreak
      const storageLocation = '1'
      const estimatedStartRent = new Date().toISOString().slice(0, 10)
      const estimatedEndRent = new Date(
        new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      // eslint-disable-next-line operator-linebreak
      const organizationName =
        organizationNames[Math.floor(Math.random() * organizationNames.length)]
      const priceClassicStorage = Math.floor(Math.random() * 100)
      const priceDeluxeStorage = Math.floor(Math.random() * 100)
      const numberClassicStorage = 1
      const numberDeluxeStorage = 1

      const storageJSON = {
        storage: [
          {
            id: 1,
            type: 'Classic Lagercontainer',
            priceStorage: '129',
            numberStorage: 1,
            estimatedEndRent: '3 Monate',
            estimatedStartRent: '01.03.2022',
          },
          {
            id: 2,
            type: 'Deluxe Lagercontainer',
            priceStorage: '189',
            numberStorage: 1,
            estimatedEndRent: '6 Monate',
            estimatedStartRent: '01.03.2022',
          },
        ],
      }
      const acceptInquiry = false
      const qualification = null
      const status = 'open'
      const offers = []
      const isOrganization = Math.random() < 0.5
      // eslint-disable-next-line camelcase
      const created_by = 'Test User'
      // eslint-disable-next-line camelcase
      const updated_by = 'Test User'

      return {
        firstName,
        lastName,
        message,
        email,
        salutation,
        street: `${streetName} ${streetNumber}`,
        city,
        zipCode,
        phoneNumber,
        leadSource,
        storageLocation,
        estimatedStartRent,
        estimatedEndRent,
        organizationName,
        priceClassicStorage,
        priceDeluxeStorage,
        numberClassicStorage,
        numberDeluxeStorage,
        storageJSON,
        acceptInquiry,
        qualification,
        status,
        offers,
        isOrganization,
        country,
        created_by,
        updated_by,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
