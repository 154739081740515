<template>
  <div class="checkout-items">
    <!-- {{ products }} -->
    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >
      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img
            :alt="`${product.name}`"
            fluid
            class="card-img-top"
            :src="`${baseURL}${product.image[0].url}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.name }}
            </b-link>
          </h6>
        </div>
        <span class="text-success mb-1">Buchbar</span>
        <div class="item-quantity">
          <span class="quantity-title">Anzahl:</span>
          <b-form-spinbutton
            v-model="product.qty"
            size="sm"
            class="ml-75"
            inline
          />
        </div>
        <div class="mb-1 mt-1">
          <span> Voraussichtlicher Mietbeginn </span>
          <flat-pickr
            v-model="product.estimatedStartRent"
            class="form-control"
            placeholder="Wählen Sie ein Datum aus"
            :config="dateConfig"
          />
        </div>

        <div class="mb-1">
          <span> Voraussichtliche Mietdauer </span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="product.estimatedEndRent"
              name="duration"
              value="1-6 Monate"
            >
              1-6 Monate
            </b-form-radio>
            <b-form-radio
              v-model="product.estimatedEndRent"
              name="duration"
              value="6-12 Monate"
            >
              6-12 Monate
            </b-form-radio>
            <b-form-radio
              v-model="product.estimatedEndRent"
              name="duration"
              value="12+ Monate"
            >
              12+ Monate
            </b-form-radio>
            <b-form-radio
              v-model="product.estimatedEndRent"
              name="duration"
              value="unwknown"
            >
              Weiß ich nicht
            </b-form-radio>
          </div>
        </div></b-card-body
      >

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">{{ product.price }} €</h4>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span>Entfernen</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BButtonGroup,
  BBadge,
  BFormSpinbutton,
  BFormDatepicker,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import API from '@/service/api'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BButtonGroup,
    BBadge,
    BFormSpinbutton,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
    flatPickr,
    German,
  },
  setup() {
    const products = ref([])

    const { toggleProductInWishlist } = useEcommerceUi()

    const { removeProductFromCart } = useEcommerce()

    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id).then(() => {
        const productIndex = products.value.findIndex(p => p.id === product.id)
        products.value.splice(productIndex, 1)

        store.commit('removeFromCart', products.value.length)
      })
    }

    const fetchCartProducts = () => {
      products.value = store.getters.cart
    }

    fetchCartProducts()

    const localProducts = ref(JSON.parse(JSON.stringify(products.value)))

    watch(
      localProducts,
      newProducts => {
        newProducts.forEach(product => {
          store.commit('UPDATE_QUANTITY', { id: product.id, qty: product.qty })
        })
      },
      { deep: true }
    )

    return {
      products,
      localProducts,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    }
  },
  data() {
    return {
      baseURL: API().defaults.baseURL,
      dateConfig: {
        altInput: true,
        altFormat: 'j.m.Y',
        utc: true,
        locale: German,
      },
      // product: { qty: 1 },
    }
  },
  methods: {
    setEndRent(product, value) {
      // eslint-disable-next-line no-param-reassign
      product.estimatedEndRent = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
