<template>
  <div>
    <div v-if="products.length === 0">Keine Lager ausgewählt.</div>
    <div class="list-view product-checkout mt-0">
      <!-- Products List -->
      <e-commerce-checkout-step-cart-products />

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>
          <div class="price-details">
            <h6 class="price-title">Hinweis</h6>
            <p>
              Dies ist keine verbindliche Bestellung, noch können wir die
              Verfügbarkeit der Container garantieren.
            </p>
            <p>
              Nach erfolgreicher Prüfung ihrer Anfrage erhalten Sie ein
              individuelles Buchungsangebot per Email zugesendet.
            </p>
            <!-- <p>Erst nach Ihrer Bestätigung wird die Buchung verbindlich.</p> -->
            <hr />
            <h6 class="price-title">Details</h6>
            <ul class="list-unstyled">
              <li
                v-for="(product, index) in products"
                :key="index"
                class="price-detail"
              >
                <div class="detail-title">
                  {{ product.qty }} x {{ product.name }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ product.price }}€
                </div>
              </li>
            </ul>

            <b-button variant="primary" block @click="$emit('next-step')">
              Buchungsanfrage
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  setup() {
    const products = ref([])

    const fetchCartProducts = () => {
      products.value = store.getters.cart
    }

    fetchCartProducts()

    return {
      products,
    }
  },
}
</script>
